<template>
  <div id="app">
    <div v-if="this.showNavBar">
      <NavBar />
    </div>
    <router-view className="app_view_default" />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import serviceWorkerUpdated from './mixins/serviceWorkerUpdated';

export default {
  name: "app",
  data() {
    return {
      showNavBar: true,
    };
  },
  components: {
    NavBar,
  },
  mounted() {
    this.$root.$on("nav_bar", (value) => {
      this.showNavBar = value;
    });
  },
  mixins: [serviceWorkerUpdated]
};
</script>
