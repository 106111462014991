<template>
  <div v-if="username !== null">
    <b-navbar class="header header__group" style="height: 60px">
      <b-navbar-brand href="/" class="brand brand--normal">
        <svg class="icon brand__logo">
          <use xlink:href="@/assets/svg/icons.svg#enefit"></use>
        </svg>
        <span class="brand__text">ENEFIT</span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="nav nav--horizontal">
          <b-nav-item exact activeClassName="active">
            <router-link to="/ui" class="u-hover">Chargers</router-link>
          </b-nav-item>
          <b-nav-item exact activeClassName="active">
            <router-link to="/ui/rfidCardsView" class="u-hover"
              >RFID Cards</router-link
            >
          </b-nav-item>
          <b-nav-item exact activeClassName="active">
            <router-link to="/ui/whitelisting" class="u-hover"
              >Whitelisting</router-link
            >
          </b-nav-item>
          <b-nav-item exact activeClassName="active">
            <router-link to="/ui/onboarding" class="u-hover"
              >Onboarding</router-link
            >
          </b-nav-item>
          <b-nav-item exact activeClassName="active">
            <router-link to="/ui/customerManagement" class="u-hover"
              >Customer Management</router-link
            >
          </b-nav-item>

          <b-nav-item exact activeClassName="active">
            <router-link to="/ui/customers" class="u-hover"
            >Customer List</router-link
            >
          </b-nav-item>
          <b-nav-item exact activeClassName="active">
            <router-link to="/ui/chargerDowntimeEvents" class="u-hover"
              >Downtime events</router-link
            >
          </b-nav-item>

          <b-nav-item-dropdown
            id="my-nav-dropdown"
            text="MDM"
            toggle-class="green-color"
          >
            <b-dropdown-item>
              <b-nav-item exact activeClassName="active">
                <router-link to="/ui/vat" class="u-hover"
                  >VAT Management</router-link
                >
              </b-nav-item>
            </b-dropdown-item>

            <b-dropdown-item>
              <b-nav-item exact activeClassName="active">
                <router-link to="/ui/holidays" class="u-hover"
                  >Holidays Management</router-link
                >
              </b-nav-item>
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item exact activeClassName="active">
            <router-link to="/ui/chargerSites" class="u-hover"
              >Charger sites</router-link
            >
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto" v-if="username !== null">
          <b-nav-item href="/" class="header__item header__item">
            <b-dropdown
              class="m-4"
              id="user"
              :text="username"
              variant="outline-success"
            >
              <b-dropdown-item @click="logout()">Logout</b-dropdown-item>
            </b-dropdown>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { BDropdown } from "bootstrap-vue";

export default {
  name: "NavBar",
  components: {
    "b-dropdown": BDropdown,
  },
  data() {
    return {
      username: localStorage.getItem("username"),
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$root.$emit("nav_bar", false);
      this.$router.push("/ui/login");
    },
  },
  mounted() {
    this.$root.$on("decode_access_token", (token) => {
      this.username = JSON.parse(atob(token.split(".")[1])).name;
      localStorage.setItem("username", this.username);
    });
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
<style>
.green-color {
  color: #0fac5f !important;
}
</style>
