import { EventSourcePolyfill } from "event-source-polyfill";

const EventSource = EventSourcePolyfill;

export function getChargePointsEvents() {
  const tenant = process.env.VUE_APP_TENANT;
  const backendUrl = process.env.VUE_APP_BACKEND_BASE_URL;

  return new EventSource(`${backendUrl}/${tenant}/chargepoints/events`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
  });
}
