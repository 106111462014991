import Vue from "vue";
import Vuex from "vuex";
import { getChargePointsEvents } from "../api/ChargePointsEvents";
import { getSelectedChargerConnectionState } from "../api/ChargerDetailsApi";
import { getChargerStatus } from "../api/ChargerStatusApi";
import { ChargePointStatus, ConnectionState } from "./models";
import isEqual from "lodash/isEqual";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    chargerStatuses: Array<ChargePointStatus>(),
    connectionStates: Array<ConnectionState>(),
    connectionUpdateTimestamps: {},
    isSseConnectionOpened: false,
    isEssentialDatasetsInitialized: false,
    isConnectionStateDatasetInitialized: false,
  },
  mutations: {
    updateChargePointStatus(state, newStatus) {
      state.chargerStatuses = [
        ...state.chargerStatuses.filter(
          ({ chargerId }) => newStatus.chargerId !== chargerId
        ),
        newStatus,
      ];
    },
    updateConnectionState(state, newConnectionState) {
      state.connectionStates = [
        ...state.connectionStates.filter(
          ({ chargerId }) => newConnectionState.chargerId !== chargerId
        ),
        newConnectionState,
      ];
    },
    updateConnectionUpdateTimestamps(state, { chargerId, newTimestamp }) {
      state.connectionUpdateTimestamps[chargerId] = newTimestamp;
    },
    initChargePointConnectionState(
      state,
      connectionStates: Array<ConnectionState>
    ) {
      state.isConnectionStateDatasetInitialized = true;
      state.connectionStates = connectionStates;
      state.connectionUpdateTimestamps = connectionStates.reduce(
        (acc, conState) => {
          return { ...acc, [conState.chargerId]: conState.timestamp };
        },
        {}
      );
    },
    initChargePointStatus(state, statuses) {
      state.chargerStatuses = statuses;
    },
    markSseConnectionAsOpened(state) {
      state.isSseConnectionOpened = true;
    },
    markEssentialDatasetsAsInitialized(state) {
      state.isEssentialDatasetsInitialized = true;
    },
  },
  actions: {
    updateChargePointStatus({ commit }, newStatus) {
      commit("updateChargePointStatus", newStatus);
    },
    updateConnectionState(
      { commit, state },
      { timestamp: newTimestamp, ...newConnectionState }: ConnectionState
    ) {
      const oldConnectionState = state.connectionStates.find(
        (connectionState) =>
          connectionState.chargerId === newConnectionState.chargerId
      );

      if (!oldConnectionState) {
        commit("updateConnectionState", newConnectionState);
      } else {
        const { timestamp: oldTimestamp, ...oldValues } = oldConnectionState;
        if (!isEqual(oldValues, newConnectionState)) {
          commit("updateConnectionState", newConnectionState);
        }
        if (oldTimestamp !== newTimestamp) {
          const chargerId = newConnectionState.chargerId;
          commit("updateConnectionUpdateTimestamps", {
            chargerId,
            newTimestamp,
          });
        }
      }
    },
    markSseConnectionAsOpened({ commit }) {
      commit("markSseConnectionAsOpened");
    },
    markEssentialDatasetsAsInitialized({ commit }) {
      commit("markEssentialDatasetsAsInitialized");
    },
    initSSEConnection({ dispatch }) {
      const es = getChargePointsEvents();
      es.onopen = () => {
        dispatch("markSseConnectionAsOpened");
      };
      es.addEventListener("connection-state", (event) => {
        dispatch("updateConnectionState", JSON.parse(event.data));
      });
      es.addEventListener("chargepoint-status", (event) => {
        dispatch("updateChargePointStatus", JSON.parse(event.data));
      });
    },

    initChargePointConnectionState({ commit }) {
      return getSelectedChargerConnectionState()
        .then((response) => {
          commit("initChargePointConnectionState", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initEssentialDatasets({ state, dispatch }) {
      if (!state.isSseConnectionOpened && state.connectionStates.length === 0) {
        Promise.all([
          dispatch("initChargePointConnectionState"),
          dispatch("initChargePointStatus"),
        ]).then(() => dispatch("markEssentialDatasetsAsInitialized"));
        dispatch("initSSEConnection");
      }
    },
    initChargePointStatus({ commit }) {
      return getChargerStatus()
        .then((response) => {
          commit("initChargePointStatus", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  getters: {
    getConnectionStateByChargerId: (state) => (chargerId: string) => {
      return state.connectionStates.find(
        (connectionState) => connectionState.chargerId === chargerId
      );
    },
    getChargerStatusByChargerId: (state) => (chargerId: string) => {
      return state.chargerStatuses.find(
        (chargerStatus) => chargerStatus.chargerId === chargerId
      );
    },
    getConnectionUpdateTimestampByChargerId: (state) => (chargerId: string) => {
      return state.connectionUpdateTimestamps[chargerId];
    },
  },
});

export default store;
