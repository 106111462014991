import { OCPP_API } from "./common/http-common";

const tenant = process.env.VUE_APP_TENANT;

export function getChargingProfile(chargerId) {
  return OCPP_API.get(`/${tenant}/chargepoints/${chargerId}/chargingprofile`);
}

export function getSelectedChargerDetail(chargerId) {
  return OCPP_API.get(
    `/${tenant}/chargepoints/${chargerId}/chargePointConfiguration`
  );
}

export function getSelectedChargerConnectionState() {
  return OCPP_API.get(
    `/${tenant}/store/v1.0/tenants/${tenant}/chargepoints/connectionState`
  );
}

export function getChargerById(chargerId) {
  return OCPP_API.get(`/${tenant}/assets/chargers/${chargerId}`);
}

export function updateChargerData(chargerId, chargerData) {
  return OCPP_API.patch(
    `/${tenant}/assets/v1.0/tenants/${tenant}/chargepoints/${chargerId}`,
    {
      ownership: chargerData.ownership,
      notes: chargerData.notes,
      address: chargerData.address,
      name: chargerData.name,
      status: chargerData.status,
      chargingRateLimit: chargerData.chargingRateLimit,
    }
  );
}

export function getChargerModels() {
  return OCPP_API.get(`/${tenant}/assets/v1.0/tenants/${tenant}/chargermodels`);
}
