import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/ui",
    name: "chargersView",
    component: () => import("@/views/ChargersView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    redirect: "/ui/login",
  },
  {
    path: "/ui/login",
    name: "loginView",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/ui/error/:error",
    name: "errorView",
    component: () => import("@/views/LoginView.vue"),
    props: true,
  },
  {
    path: "/ui/detailsView/:chargerId",
    name: "detailsView",
    component: () => import("@/views/ChargerManagementView.vue"),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/ui/authorize",
    name: "TenantAuthCallback",
    component: () => import("@/components/TenantAuthCallback.vue"),
  },
  {
    path: "*",
    name: "Non existant path",
    redirect: "/ui/error/404 path does not exists",
  },
  {
    path: "/ui/rfidCardsView",
    name: "rfidCardsView",
    component: () => import("@/views/RfidCardsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ui/onboarding",
    name: "chargerOnboardingView",
    component: () => import("@/views/ChargerOnboardingView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ui/whitelisting",
    name: "chargerWhitelistingView",
    component: () => import("@/views/ChargerWhitelistingView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ui/customerManagement",
    name: "customerManagementView",
    component: () => import("@/views/CustomerManagementView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ui/chargerDowntimeEvents",
    name: "chargerDowntimeEventsView",
    component: () => import("@/views/ChargerDowntimeEventsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ui/vat",
    name: "vatManagementView",
    component: () => import("@/views/VatManagementView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ui/holidays",
    name: "holidaysManagementView",
    component: () => import("@/views/HolidaysManagementView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ui/chargerSites",
    name: "chargerSitesView",
    component: () => import("@/views/ChargerSitesView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ui/customers",
    name: "customersView",
    component: () => import("@/views/CustomersView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      if (localStorage.getItem("username") !== null) {
        localStorage.removeItem("username");
      }
      next({
        path: "/ui/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
