import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(BootstrapVue);
Vue.use(Vuetify);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify: new Vuetify({
    icons: {
      iconfont: "mdiSvg",
    },
  }),
  render: (h) => h(App),
}).$mount("#app");
