import axios from "axios";
import { subMinutes, isAfter } from "date-fns";

export const OCPP_API = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
});

export const OCPP_AUTH_API = axios.create({
  baseURL: process.env.VUE_APP_AUTHENTICATOR_BASE_URL,
});

OCPP_API.interceptors.request.use(
  (config) => {
    config.headers = refreshToken();

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function refreshToken() {
  const url = `${process.env.VUE_APP_AUTHORITY}`;
  const clientId = `client_id=${process.env.VUE_APP_CLIENT_ID}`;
  const responseType = `response_type=${process.env.VUE_APP_RESPONSE_TYPE}`;
  const redirectUrl = `redirect_uri=${process.env.VUE_APP_REDIRECT_URI}`;
  const responseMode = `response_mode=${process.env.VUE_APP_RESPONSE_MODE}`;
  const scope = `scope=${process.env.VUE_APP_SCOPE}`;
  const state = `state=${process.env.VUE_APP_STATE}`;

  console.log("refresh token called");
  const tokenExpiryDate = localStorage.getItem("tokenExpiry");
  console.log(tokenExpiryDate);
  if (!tokenExpiryDate) {
    localStorage.clear();
    window.location.assign("/ui/login");
  } else {
    const tenMinutesBeforeExpiry = subMinutes(Date.parse(tokenExpiryDate), 10);
    console.log(tenMinutesBeforeExpiry);

    if (isAfter(Date.now(), Date.parse(tokenExpiryDate))) {
      console.log("Token expired..redirecting to login page");
      localStorage.clear();
      window.location.assign("/ui/login");
    }

    if (isAfter(Date.now(), tenMinutesBeforeExpiry)) {
      console.log("Token is about to expire, renewing..");
      localStorage.removeItem("jwt");
      window.location.assign(
        `${url}?${clientId}&${responseType}&${redirectUrl}&${responseMode}&${scope}&${state}`
      );
    } else {
      console.log("Token is still valid");
    }
  }
  return {
    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  };
}
